import React from "react";
import styles from './MainAbout.module.scss';
import AboutHeader from "./AboutHeader/AboutHeader";
import GetStarted from "./GetStarted/GetStarted";
import TrustedBy from "./TrustedBy/TrustedBy";
import WhatIS from "./WhatIS/WhatIS";
import AboutFooter from "./AboutFooter/AboutFooter";
import UseCases from "./UseCases/UseCases";
import CrossChain from "./CrossChain/CrossChain";
import WhatAPI from "./WhatAPI/WhatAPI";
import ApiDocs from "./ApiDocs/ApiDocs";
import ExploreApis from "./ExploreApis/ExploreApis";
import AboutUs from "./AboutUs/AboutUs";
import LetsStarted from "./LetsStarted/LetsStarted";
import background from "../../assets/backgrounds/gradientMain.svg";
import backgroundMob from "../../assets/backgrounds/gradientMainMob.svg";

const MainAbout = () => {
    return (
        <div className={styles.mainAbout}>
            <div className={styles.content}>
                <span className={styles.backgroundDecor}
                      style={ window.innerWidth > 767 ? {backgroundImage: `url(${background})`} : {backgroundImage: `url(${backgroundMob})`}}/>
                <AboutHeader />
                <GetStarted />
                <TrustedBy />
                <WhatIS />
                <UseCases />
                <CrossChain />
                <WhatAPI />
                <ApiDocs />
                <ExploreApis />
                <AboutUs />
                <LetsStarted />
                <AboutFooter />
            </div>
        </div>
    )
}

export default MainAbout;