import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    blogs: [
        {id: 1, title: 'What is Y-API and why do you need it?', date: 'Jan 10, 2024'},
        {id: 2, title: 'How to skip nodrunning with Y-API and save your money and nerves?', date: 'Jan 24, 2024'},
        {id: 3, title: 'Your own cryptowallet with Y-API is simple, fast and very convenient', date: 'February 16, 2024'},
        {id: 4, title: 'Track the blockchain events you need with Y-API', date: 'March 20, 2024'},
        {id: 5, title: 'Integration of Bitcoin API into the WEB 3.0 project using ready-made solutions from Y-API', date: 'April 25, 2024'},
        {id: 6, title: 'Waiting for the MPC Cryptowallets — the upcoming update from the yuppie', date: 'June 30, 2024'}
    ]
};

export const mainSlice = createSlice({
    name: 'mainPage',
    initialState,
    reducers: {

    }
})

export default mainSlice.reducer;

export const {
} = mainSlice.actions;