import './Base.scss';
import React from "react";
import MainAbout from "./components/MainAbout/MainAbout";
import {Routes, Route} from "react-router-dom"
import UseCasesPage from "./components/UseCasesPage/UseCasesPage";
import Blog from "./components/Blog/Blog";
import Pricing from "./components/Pricing/Pricing";
import BlogView from "./components/BlogView/BlogView";
import StartForm from "./components/StartForm/StartForm";

function App() {
  return (
    <div>
      <Routes>
        <Route index element={<MainAbout />} />
        <Route path={'/useCasesPage/*'} element={<UseCasesPage />} />
        <Route path={'/blogs/*'} element={<Blog />} />
        <Route path={'/blogs/:id'} element={<BlogView/>}/>
        <Route path={'/pricing/*'} element={<Pricing />} />
        <Route path={'/getStart/*'} element={<StartForm />} />
      </Routes>
    </div>
  );
}

export default App;
