import React from "react";
import styles from './StartForm.module.scss';
import close from '../../assets/icons/close.svg';
import {Link} from "react-router-dom";
import FormFields from "./FormFields";

const StartForm = () => {
    return (
        <div className={'darkWrapper'}>
            <div className={'darkWrapper__content'}>
                <div className={styles.startForm}>
                    <div className={styles.close}>
                        <Link to={'/'}><img src={close} alt=""/></Link>
                    </div>
                    <div className={styles.content}>
                        <div className={styles.content__info}>
                            <h2>Connect with our team</h2>
                            <p>Drop us your details and our team will get in touch to see how we can help.</p>
                        </div>
                        <FormFields />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StartForm;