import React from "react";
import styles from './BlogView.module.scss';

const BlogItem5 = () => {
    return (
        <>
            <div>
                <p className={styles.BlogView__description}>
                    To grasp the fundamental concept of the Blockchain API and its applications, it's essential to
                    summarize the essence of an API. You may be familiar with this concept, but Y-API aims to provide a
                    deeper understanding.
                </p>
                <div className={styles.BlogView__block}>
                    <h4>What is an API?</h4>
                    <p>API stands for Application Programming Interface, providing an abstract layer for interacting
                        with software, simplifying the underlying implementation. Essentially, an API allows you to
                        access another developer's application without needing all the code. The key aspect here is
                        access.</p>
                    <p className={styles.beforeList}>APIs come in different types based on access restrictions,
                        depending on the software process being followed. Let's delve into the Bitcoin blockchain. The
                        main steps in the network are:</p>
                    <ul className={styles.numericList}>
                        <li>A Bitcoin client creates a transaction that is executed on the blockchain and then enters
                            the memory pool.
                        </li>
                        <li>A miner processes the transaction and includes it in a block.</li>
                        <li>Within 10-20 minutes, the transaction is confirmed and connected to the network.</li>
                    </ul>
                    <p>This process is quite complex. Therefore, the Bitcoin API is offered as a network server to
                        create various applications, such as block explorers or wallets. It provides the necessary tools
                        for developers or users to customize their queries, saving time and allowing them to focus on
                        the application's overall logic.</p>
                </div>
                <div className={styles.BlogView__block}>
                    <h4>How to use the Bitcoin API?</h4>
                    <p className={styles.beforeList}>To use the endpoints, you need an API key for authentication.
                        First, generate an API key in the IP dashboard by clicking "Generate a new API key." This key
                        can be used in development, quality control, and production.</p>
                    <ul>
                        <li>Content-Type: application/json</li>
                        <li>X-API-Key: my-api-key</li>
                    </ul>
                </div>
                <div className={styles.BlogView__block}>
                    <h4>Endpoint for General Information</h4>
                    <p>To obtain general information about a node or the last block, use the GET method on the resource.
                        The returned object will contain information about the blockchain, such as its height, time/hash
                        of the last block, and more.</p>
                </div>
                <div className={styles.BlogView__block}>
                    <h4>Block Endpoint</h4>
                    <p>To request a specific block, you can use a hash string or height as parameters. The returned
                        object will provide information about the block in JSON format, including its height, the number
                        of transactions, and the transaction hashes in the order they appear in the block.</p>
                </div>
                <div className={styles.BlogView__block}>
                    <h4>Address Endpoints</h4>
                    <p>The API can also retrieve information on addresses, wallets, transactions, payment forwarding,
                        webhook notifications, and more. For example, you can get information about a Bitcoin address,
                        including its balance and associated transactions, using the default address endpoint. If the
                        address is part of a multisig address, Y-API provides a Multisig address endpoint.</p>
                </div>
                <div className={styles.BlogView__block}>
                    <h4>Creating Wallet Endpoints</h4>
                    <p>The Address API can handle both HD wallets and regular wallets using their $NAME instead of
                        $ADDRESS. This groups the set of addresses contained in the wallet. To create a new wallet, POST
                        a partially populated Wallet or HDWallet object, depending on the endpoint.</p>
                    <p>For regular wallets, include the WALLET_NAME attribute and at least one public address in the
                        ADDRESSES array:</p>
                    <p>{`{"walletName": \${WALLET_NAME},"addresses": \${ADDRESSES}}`}</p>
                    <p>For HD wallets, include the WALLET_NAME, ADDRESS_COUNT, and PASSWORD attributes:</p>
                    <p>{`{"walletName": \${WALLET_NAME},"addressCount": \${ADDRESS_COUNT},"password": \${PASSWORD}}`}</p>
                    <p>If successful, it will return a Wallet or HDWallet object with the requested data.</p>
                </div>
                <div className={styles.BlogView__block}>
                    <h4>Integrating APIs into Your Website</h4>
                    <p>Here are some examples of how endpoints help integrate APIs into your website using Y-API's ready-made solutions. If you have other requirements, our professional team is ready to assist you!</p>
                </div>
            </div>
        </>
    )
}

export default BlogItem5;