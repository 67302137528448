import React from "react";
import styles from './GetStarted.module.scss';
import backgroundLeft from "../../../assets/backgrounds/getStartedLeft.svg";
import backgroundRight from "../../../assets/backgrounds/getStartedRight.svg";

const GetStarted = () => {
    return (
        <div className={styles.getStarted}>
            <div className={styles.box}>
                <span className={styles.box__decorLeft} style={ window.innerWidth > 767 ? {backgroundImage: `url(${backgroundLeft})`} : null} />
                <span className={styles.box__decorRight} style={ window.innerWidth > 767 ? {backgroundImage: `url(${backgroundRight})`} : null} />
                <h3>Ready to Start Building?</h3>
                <div className={styles.box__actions}>
                    <button className='colorBtn'><span>Start for free</span></button>
                    <a href="#">Contact Sales</a>
                </div>
                <div className={styles.box__login}>
                    <p>Already have an account? <a href="">Log in</a></p>
                </div>
            </div>
        </div>
    )
}

export default GetStarted;