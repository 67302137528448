import React from "react";
import styles from './ApiDocs.module.scss';
import image from '../../../assets/images/apiDoc.png';
import background from "../../../assets/backgrounds/apiDocs.svg";
import backgroundMob from "../../../assets/backgrounds/apiDocsMob.svg";

const ApiDocs = () => {
    return (
        <div className={styles.apiDocs}>
            <span className={styles.apiDocs__decor}
                  style={window.innerWidth > 767 ? {backgroundImage: `url(${background})`} :
                  {backgroundImage: `url(${background})`}} />
            <div className={styles.apiDocs__info}>
                <h3>API Docs</h3>
                {window.innerWidth < 768 && (
                    <div className={styles.apiDocs__image}>
                        <img src={image} alt=""/>
                    </div>
                )}
                <p className={styles.apiDocs__description}>Our team has spent a lot of time and effort to ensure that our
                    documentation meets the highest standards of information quality. The most complete description of
                    all features allows you to quickly understand our product and use it efficiently for maximum
                    benefit.
                </p>
                <div className={styles.apiDocs__actionWrapper}>
                    <button className={`${styles.apiDocs__action} colorBtn`}>Try it now</button>
                </div>
            </div>
            {window.innerWidth > 767 && (
                <div className={styles.apiDocs__image}>
                    <img src={image} alt=""/>
                </div>
            )}
        </div>
    )
}

export default ApiDocs;