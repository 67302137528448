import React from "react";
import styles from './WhatIS.module.scss';
import integration from '../../../assets/icons/integration.svg';
import perfomace from '../../../assets/icons/perfomace.svg';
import security from '../../../assets/icons/security.svg';
import background from "../../../assets/backgrounds/WhatIs.svg";

const WhatIS = () => {
    return (
        <div className={styles.whatIS} >
            <span className={styles.whatIS__decor} style={{backgroundImage: `url(${background})`}}/>
            <h2>What is Y-API?</h2>
            <div className={styles.whatIS__items}>
                <div className={styles.whatIS__integration}>
                    <img src={integration} alt=""/>
                    <p>Seamless one-time integration</p>
                </div>
                <div className={styles.whatIS__perfomace}>
                    <img src={perfomace} alt=""/>
                    <p>Streamlined and lightweight performance</p>
                </div>
                <div className={styles.whatIS__security}>
                    <img src={security} alt=""/>
                    <p>Advanced cryptographic security</p>
                </div>
            </div>
        </div>
    )
}

export default WhatIS;