import React, {useState} from "react";
import styles from './WhatAPI.module.scss';
import Tabs from "./Tabs";
import wallet from "../../../assets/icons/cryptoWallet.svg";
import events from "../../../assets/icons/realTimeEvents.svg";
import data from "../../../assets/icons/blockchainData.svg";
import background from "../../../assets/backgrounds/whatApis.svg";
import backgroundMob from "../../../assets/backgrounds/gradientMainMob.svg";

const WhatAPI = () => {
    const tabs = [
        {
            id: 1,
            tabText: 'Crypto wallet',
            icon: wallet,
            text: 'Get a ready-made multi-currency crypto wallet with a large set of built-in functions. Store and transfer different types of cryptocurrency, manage tokens and multiply crypto assets. ',
            subText: 'We took care of fast work and eliminated the main stress points of modern crypto wallets, like key recovery.'
        },
        {
            id: 2,
            tabText: 'Real-time events',
            icon: events,
            text: 'A wide range of unified queries and callbacks are at your disposal. Subscribe to blocks, currency or token transactions and receive real-time notifications.',
            subText: 'Fast, secure and simplified for best use. The speed is less than 100 ms. Now you will get the endpoint exactly on time for the desired event.'
        },
        {
            id: 3,
            tabText: 'Blockchain data',
            icon: data,
            text: 'Get the data you need from the blockchain in less than 25 ms. Thanks to just one point and REST API methods, you always have access to complex and dynamic databases.',
            subText: 'Configure requests to blockchain protocols, find out account balances, study transaction activity and much more with API by Y-API'
        },
    ]

    const [showContent, setShowContent] = useState(tabs[0]);

    return (
        <div className={styles.whatAPI}>
            <h2>What APIs do?</h2>
            <div className={styles.wrapper}>
                <div className={styles.tabs}>
                    <Tabs tabs={tabs} setShowContent={setShowContent} showContent={showContent} />
                </div>
                {window.innerWidth > 767 && (
                    <div className={styles.content} style={ window.innerWidth > 767 ? {backgroundImage: `url(${background})`} : null}>
                        <span>{showContent.text}</span>
                        <p>{showContent.subText}</p>
                    </div>
                )}
            </div>
        </div>
    )
}

export default WhatAPI;