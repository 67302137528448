import React from "react";
import styles from './BlogMain.module.scss';
import bgImage from "../../../assets/backgrounds/blogMainBg.svg";

const BlogMain = () => {
    return (
        <div className={styles.blogMain}>
            <span className={styles.decor} style={{backgroundImage: `url(${bgImage})`}}/>
            <p className={styles.date}>April 10, 2024</p>
            <h3>How to Build a Crypto Wallet Timeline — Wallet History API</h3>
            <p className={styles.text}>The number one blog for Web3 development. Join hundreds of thousands of readers in unraveling Web3 and
                learn how to build dapps at blazing speeds with Y-API!
            </p>
            <button className={styles.action}>Read More</button>
        </div>
    )
}

export default BlogMain;