import React from "react";
import styles from './LetsStarted.module.scss';
import backgroundLeft from "../../../assets/backgrounds/getStartedLeft.svg";
import backgroundRight from "../../../assets/backgrounds/getStartedRight.svg";
import backgroundMob from "../../../assets/backgrounds/let’sGetMob.svg";
import {Link} from "react-router-dom";

const LetsStarted = () => {
    return (
        <div className={styles.letsStarted} style={ window.innerWidth < 768 ? {backgroundImage: `url(${backgroundMob})`} : null}>
            <span className={styles.letsStarted__decorLeft} style={ window.innerWidth > 767 ? {backgroundImage: `url(${backgroundLeft})`} : null} />
            <span className={styles.letsStarted__decorRight} style={ window.innerWidth > 767 ? {backgroundImage: `url(${backgroundRight})`} : null} />
            <h3 className={styles.letsStarted__title}>Let’s get started</h3>
            <p className={styles.letsStarted__text}>Our APIs are ready and waiting for you. All that is needed now is to choose the right one and start
                implementing your idea together with Y-API
            </p>
            <div className={styles.letsStarted__actions}>
                <button className={`${styles.letsStarted__actionBook} colorBtn`}>Book a demo</button>
                <Link to={'/Pricing/'} className={`${styles.letsStarted__actionPricing}`}>Pricing</Link>
            </div>
        </div>
    )
}

export default LetsStarted;