import React from "react";
import styles from './PricingFooter.module.scss';
import linkedin from "../../../assets/icons/linkedin.svg";
import linkedinLight from "../../../assets/icons/linkedInClr.svg";
import discord from "../../../assets/icons/discord.svg";
import discordLight from "../../../assets/icons/discordClr.svg";
import facebook from "../../../assets/icons/facebook.svg";
import facebookLight from "../../../assets/icons/facebookClr.svg";

const PricingFooter = ({light = false}) => {
    return (
        <div className={styles.pricingFooter}>
            <div className={styles.pricingFooter__info}>
                <span>Copyright © 2024</span>
                <span>Privacy Policy</span>
            </div>
            <div className={styles.pricingFooter__socials}>
                <img src={light ? linkedinLight : linkedin} alt=""/>
                <img src={light ? discordLight : discord} alt=""/>
                <img src={light ? facebookLight : facebook} alt=""/>
            </div>
        </div>
    )
}

export default PricingFooter;