import React from "react";
import styles from './AboutFooter.module.scss';
import linkedin from '../../../assets/icons/linkedin.svg';
import discord from '../../../assets/icons/discord.svg';
import facebook from '../../../assets/icons/facebook.svg';

const AboutFooter = () => {
    return (
        <div className={styles.aboutFooter}>
            <div className={styles.aboutFooter__info}>
                <span>Copyright © 2024</span>
                <span>Privacy Policy</span>
            </div>
            <div className={styles.aboutFooter__socials}>
                <img src={linkedin} alt=""/>
                <img src={discord} alt=""/>
                <img src={facebook} alt=""/>
            </div>
        </div>
    )
}

export default AboutFooter;