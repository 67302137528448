import React, {useState} from "react";
import styles from './StartForm.module.scss';

const FormFields = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [comment, setComment] = useState('');

    const handleChange = (type, value) => {
        switch (type) {
            case 'name' :
                setName(value);
                break;
            case 'email' :
                setEmail(value);
                break;
            case 'phone' :
                setPhone(value);
                break;
            case 'comment' :
                setComment(value);
                break;
            default:
                break;
        }
    }

    const onSubmit = () => {

    }

    return (
        <div className={styles.formFieldsWrapper}>
            <div className={styles.formFields}>
                <input type="text" placeholder={'name*'} value={name} onChange={(e) => handleChange('name', e.target.value)} />
                <input type="text" placeholder={'e-mail*'} value={email} onChange={(e) => handleChange('email', e.target.value)} />
                <input type="text" placeholder={'phone*'} value={phone} onChange={(e) => handleChange('phone', e.target.value)} />
                <textarea placeholder={'Comment*'} value={comment} onChange={(e) => handleChange('comment', e.target.value)} />
            </div>
            <div className={styles.submit}>
                <button className={'colorBtn'} onClick={() => onSubmit()}>
                    <span>Submit</span>
                </button>
                <span className={styles.submit__text}>By clicking the button, you consent to the processing of personal data and agree to the privacy policy</span>
            </div>
        </div>

    )
}

export default FormFields;