import React from "react";
import styles from './Blogs.module.scss';
import { Link } from 'react-router-dom';

const BlogItem = ({title, date, id, change}) => {
    return (
        <div className={styles.blogItem}>
            <p className={styles.blogItem__date}>{date}</p>
            <h4 className={styles.blogItem__title}>{title}</h4>
            <Link onClick={() => window.scrollTo(0,0)} className={styles.blogItem__link} to={change ? `/blogs/${id}` : `${id}`}>Learn More</Link>
        </div>
    )
}

export default BlogItem;