import React from "react";
import styles from './CrossChain.module.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import 'swiper/swiper-bundle.css';
import 'swiper/css';
import avalanche from '../../../assets/icons/avalanche.svg';
import base from '../../../assets/icons/base.svg';
import solana from '../../../assets/icons/solana.svg';
import binance from '../../../assets/icons/binance.svg';
import optimism from '../../../assets/icons/optimism.svg';
import gnosis from '../../../assets/icons/gnosis.svg';
import fantom from '../../../assets/icons/fantom.svg';

const CrossChain = () => {
    const cross = [
        {icon: avalanche, name: 'Avalanche'},
        {icon: base, name: 'Base'},
        // {icon: solana, name: 'Solana'},
        {icon: binance, name: 'Binance'},
        {icon: optimism, name: 'Optimism'},
        {icon: gnosis, name: 'Gnosis'},
        {icon: fantom, name: 'Fantom'},
        {icon: binance, name: 'Binance'},
        {icon: optimism, name: 'Optimism'},
        {icon: gnosis, name: 'Gnosis'},
        {icon: fantom, name: 'Fantom'},
        {icon: gnosis, name: 'Gnosis'},
        {icon: binance, name: 'Binance'},
        {icon: optimism, name: 'Optimism'},
    ]

    const winWid = window.innerWidth;


    return (
        <div className={styles.crossChain}>
            <h2>Cross-chain compatible</h2>
            <Swiper
                modules={[Autoplay]}
                spaceBetween={50}
                slidesPerView={winWid > 1280 ? 8.5
                    : winWid < 1280 && winWid > 1024 ? 7.5
                        : winWid < 1024 && winWid > 768 ? 5.5
                            : winWid < 768 && winWid > 600 ? 4.5 : 3}
                autoplay={{
                    delay: 1000,
                    disableOnInteraction: false,
                }}
                speed={1000}
            >
                {cross && (
                    cross.map((item, index) => <SwiperSlide key={index}>
                        <div className={styles.slide}>
                            <img src={item.icon} alt=""/>
                            <p>{item.name}</p>
                        </div>
                    </SwiperSlide>)
                )}
            </Swiper>
        </div>
    )
}

export default CrossChain;