import React, {useState} from "react";
import styles from './AboutHeader.module.scss';
import logo from '../../../assets/images/newLogo.svg'
import logoDark from '../../../assets/images/newLogoDark.svg'
import AboutNavigation from "./AboutNavigation";
import {Link} from "react-router-dom";

const AboutHeader = ({theme = 'dark'}) => {
    const [showNavigation, setShowNavigation] = useState(false)


    return (
        <div className={theme === 'dark' ? styles.aboutHeader : styles.aboutHeaderLight}>
            <Link to={'/'}><img src={theme === 'dark' ? logo : logoDark} alt=""/></Link>
            <AboutNavigation theme={theme} showNavigation={showNavigation}/>
            <div className={styles.mobileNav}>
                <div className={theme === 'dark' ? styles.auth : styles.authLight}>
                    {window.innerWidth > 768 && (
                        <a className={theme === 'dark' ? styles.auth__login : styles.authLight__login} href="#">Login</a>
                    )}
                    <a className={`${styles.auth__register} colorBtn`} href="#">Sign Up</a>
                </div>
                {window.innerWidth < 768 && (
                    <button className={showNavigation ? styles.burgerClose : styles.burger} onClick={() => setShowNavigation(!showNavigation)}>
                        <span className={theme === 'dark' ? '' : styles.burgerLight} style={showNavigation ? {backgroundColor: 'transparent'} : null} />
                    </button>
                )}
            </div>
        </div>
    )
}

export default AboutHeader;