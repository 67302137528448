import React from "react";
import styles from './BlogView.module.scss';
import AboutHeader from "../MainAbout/AboutHeader/AboutHeader";
import {useNavigate, useParams} from "react-router";
import {useAppSelector} from "../../hooks/redux";
import {getBlogById} from "../../store/selectors";
import Blogs from "../Blog/Blogs/Blogs";
import PricingFooter from "../Pricing/PricingFooter/PricingFooter";
import BlogItem1 from "./BlogItem1";
import BlogItem2 from "./BlogItem2";
import BlogItem3 from "./BlogItem3";
import BlogItem4 from "./BlogItem4";
import BlogItem5 from "./BlogItem5";
import BlogItem6 from "./BlogItem6";


const BlogView = () => {
    const {id} = useParams();
    const blogItem = useAppSelector((state) => getBlogById(state, id));
    const navigate = useNavigate();

    const renderBlogItem = () => {
        switch (id) {
            case '1':
                return <BlogItem1 />;
            case '2':
                return <BlogItem2 />;
            case '3':
                return <BlogItem3 />;
            case '4':
                return <BlogItem4 />;
            case '5':
                return <BlogItem5 />;
            case '6':
                return <BlogItem6 />;
            default:
                return <div>This article doesn't exist</div>;
        }
    };

    return (
        <>
            <div className={'lightWrapper'}>
                <div className={'lightWrapper__content'}>
                    <AboutHeader theme={'light'} />
                    <div className={styles.BlogView}>
                        <p className={styles.BlogView__date}>{blogItem.date}</p>
                        <h3 className={styles.BlogView__title}>{blogItem.title}</h3>
                    </div>
                    {renderBlogItem()}
                    <div className={styles.BlogView__action}>
                        <button onClick={() => navigate(-1)} className={styles.back}>
                            <span>Back to the blog</span>
                        </button>
                    </div>
                </div>
            </div>
            <div className={'grayWrapper'}>
                <div className={'grayWrapper__content'}>
                    <h4 className={styles.BlogView__blogsTitle}>You may also like</h4>
                    <Blogs change/>
                    <PricingFooter light/>
                </div>
            </div>
        </>

    )
}

export default BlogView;