import React, {useState} from "react";
import styles from './Pricing.module.scss';
import AboutHeader from "../MainAbout/AboutHeader/AboutHeader";
import HeaderInfo from "./HeaderInfo/HeaderInfo";
import Tariffs from "./Tariffs/Tariffs";
import GetStarted from "./GetStarted/GetStarted";
import PricingFooter from "./PricingFooter/PricingFooter";

const Pricing = () => {
    const [tariffPeriod, setTariffPeriod] = useState('Annually');

    return (
        <div className={styles.pricing}>
            <div className={'lightWrapper'}>
                <div className={'lightWrapper__content'}>
                    <AboutHeader theme={'light'} />
                    <HeaderInfo tariffPeriod={tariffPeriod} setTariffPeriod={setTariffPeriod}/>
                    <Tariffs />
                </div>
            </div>
            <div className={'darkWrapper'}>
                <div className={'darkWrapper__content'}>
                    <GetStarted />
                    <PricingFooter />
                </div>

            </div>
        </div>
    )
}

export default Pricing;