import React from "react";
import styles from './TrustedBy.module.scss';
import linktree from '../../../assets/icons/linktree.svg';
import slack from '../../../assets/icons/slack.svg';
import meta from '../../../assets/icons/meta.svg';
import paypal from '../../../assets/icons/payPal.svg';
import microsoft from '../../../assets/icons/microsoft.svg';
import google from '../../../assets/icons/google.svg';
import amazon from '../../../assets/icons/amazon.svg';

const TrustedBy = () => {
    const companies = [
        {icon: linktree},
        {icon: slack},
        {icon: meta},
        {icon: paypal},
        {icon: microsoft},
        {icon: google},
        {icon: amazon},
    ]
    return (
        <div className={styles.trustedBy}>
            <p className={styles.title}>Trusted by 100+ companies</p>
            <div className={styles.companies}>
                {companies && (
                    companies.map((item, index) => (
                        <img key={index} src={item.icon} alt=""/>
                    ))
                )}
            </div>
        </div>
    )
}

export default TrustedBy;