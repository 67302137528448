import React from "react";
import styles from './BlogView.module.scss';

const BlogItem4 = () => {
    return (
        <>
            <div>
                <p className={styles.BlogView__description}>
                    Every decentralized network is an endless ocean of information. Whoever controls this information
                    holds significant power. If you need to track blockchain events, choosing Y-API is a wise decision.
                </p>
                <div className={styles.BlogView__block}>
                    <h4>What events are happening in the blockchain?</h4>
                    <p>Blockchain Events Y-API offers real-time notifications about various activities on the
                        blockchain. These can include transactions and specific cases, which we will detail below.</p>
                    <p className={styles.beforeList}>It’s important to remember that blockchain events are crucial for
                        evaluating the health, behavior, and performance of a blockchain infrastructure. The data
                        provided can serve various purposes based on the client's specific needs, such as:</p>
                    <ul>
                        <li>Analysis</li>
                        <li>Audit</li>
                        <li>Creation of tools</li>
                        <li>Development of tracking platforms, and more</li>
                    </ul>
                    <p>Blockchain events can “listen” for certain actions on the chain and report when they occur.</p>
                </div>
                <div className={styles.BlogView__block}>
                    <h4>What are the advantages of tracking?</h4>
                    <p>Beyond the ability to track specific activities in real-time, such as token transfers, one major
                        advantage of blockchain events is that they are more cost-effective to obtain and exchange
                        details than using the dedicated storage employed by smart contracts.</p>
                    <p className={styles.beforeList}>WEB3 blockchain events can include up to 4 parameters (also called
                        topics) associated with a single event, each being 32 bytes in size. Examples of these
                        parameters are:</p>
                    <ul>
                        <li>"Owner"</li>
                        <li>"Spender"</li>
                        <li>"Value" etc</li>
                    </ul>
                    <p className={styles.beforeList}>
                        Y-API offers ready-made solutions for the most common blockchain events, including:
                    </p>
                    <ul>
                        <li>Creating a block</li>
                        <li>Transaction verification</li>
                        <li>Network renewal, branching, and chain reorganization</li>
                    </ul>
                </div>
                <div className={styles.BlogView__block}>
                    <h4>Creating a block</h4>
                    <p>Whenever a new block is added to the blockchain, it is recorded as a blockchain event. In
                        blockchain protocols, these blocks are containers holding a set of verified and confirmed
                        transactions. When a block is created, it is added to the existing blockchain, forming a chain
                        of blocks. Block creation involves solving complex mathematical equations and algorithms, a
                        process known as mining or block proposal. Miners compete to solve these equations, and the
                        first to succeed earns the right to create a new block.</p>
                </div>
                <div className={styles.BlogView__block}>
                    <h4>Transaction verification</h4>
                    <p>Transaction verification involves a set of rules to confirm the authenticity of a transaction.
                        Blockchain nodes independently verify the transaction structure to prevent double spending.
                        Through consensus, the validity of a transaction is agreed upon and added to a block.</p>
                </div>
                <div className={styles.BlogView__block}>
                    <h4>Network renewal, branching, and chain reorganization</h4>
                    <p>Various events related to changes in blockchain protocols can be tracked as blockchain events.
                        These include protocol changes, network updates, software updates, forks, and chain
                        reorganization. Such events are key and are communicated to the user.</p>
                </div>
                <div className={styles.BlogView__block}>
                    <h4>API benefits from our service</h4>
                    <p>With Y-API's advanced blockchain infrastructure, users receive notifications on verified endpoints when an event occurs. Users get information about newly mined blocks, transaction confirmations, and can create callback subscriptions for incoming or outgoing client transactions with a response to each received confirmation, happening before the transaction confirmation limit is reached. All callback information is returned for each individual address within 100 ms.</p>
                    <p>If you are interested in tracking blockchain events, contact the Y-API team to subscribe to a convenient, efficient service and receive comprehensive support for your project.</p>
                </div>
            </div>
        </>
    )
}

export default BlogItem4;