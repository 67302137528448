import React from "react";
import styles from './AboutHeader.module.scss';
import linkedin from '../../../assets/icons/linkedin.svg';
import linkedinClr from '../../../assets/icons/linkedInClr.svg';
import discord from '../../../assets/icons/discord.svg';
import discordClr from '../../../assets/icons/discordClr.svg';
import facebook from '../../../assets/icons/facebook.svg';
import facebookClr from "../../../assets/icons/facebookClr.svg";
import {Link} from "react-router-dom";
import {useLocation} from "react-router";


const AboutNavigation = ({showNavigation, theme}) => {
    const {pathname} = useLocation();

    const navigation = [
        {name: 'What is?', path: null},
        {name: 'Use Cases', path: null},
        {name: 'Integrations', path: null},
        {name: 'API Docs', path: null},
        {name: 'About Us', path: null},
        {name: 'Blog', path: '/blogs/'},
        {name: 'Pricing', path: '/pricing/'},
    ]

    return (
        <div className={showNavigation ? styles.navigationActive : styles.navigation}
             style={theme === 'dark' ? null : {backgroundColor: '##FEFDFF'}}>
            <div className={theme === 'dark' ? styles.links : styles.linksLight}>
                {navigation && (
                    navigation.map((item, index) => <Link className={pathname === item.path ? styles.active : null}
                                                          key={index} to={item.path}><span>{item.name}</span></Link>)
                )}
                {window.innerWidth < 768 && (
                    <a href="#"><span>Login</span></a>
                )}
            </div>
            {window.innerWidth < 768 && (
                <div className={styles.navigation__footer}>
                    <span>Contact us</span>
                    <div className={styles.navigation__socials}>
                        <a href=""><img src={theme === 'dark' ? linkedin : linkedinClr} alt=""/></a>
                        <a href=""><img src={theme === 'dark' ? discord : discordClr} alt=""/></a>
                        <a href=""><img src={theme === 'dark' ? facebook : facebookClr} alt=""/></a>
                    </div>
                </div>
            )}
        </div>
    )
}

export default AboutNavigation;