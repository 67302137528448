import React from "react";
import AboutHeader from "../MainAbout/AboutHeader/AboutHeader";
import BlogMain from "./BlogMain/BlogMain";
import Blogs from "./Blogs/Blogs";
import PricingFooter from "../Pricing/PricingFooter/PricingFooter";
import styles from './Blog.module.scss';

const Blog = () => {
    return (
        <>
            <div className={'lightWrapper'}>
                <div className={'lightWrapper__content'}>
                    <AboutHeader theme={'light'}/>
                    <BlogMain/>
                </div>

            </div>
            <div className={'grayWrapper'}>
                <div className={'grayWrapper__content'}>
                    <h3 className={styles.blogTitle}>Latest from our blog</h3>
                    <Blogs/>
                    <PricingFooter light/>
                </div>
            </div>
        </>


    )
}

export default Blog;