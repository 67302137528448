import React from "react";
import styles from './Tariffs.module.scss';
import Tariff from "./Tariff";

const Tariffs = () => {
    const tariffs = [
        {
            name: 'Free Access',
            subName: 'For developers that are just getting started',
            price: 'Free',
            priceInfo: '',
            buttonText: 'Get Started',
            tariffOptions: 'Access to:',
            optionList: ['40,000 Compute Units per day', 'Access to All Networks', 'Access to RPC Nodes']
        },
        {
            name: 'Free Access',
            subName: 'For professionals working in blockchain',
            price: '10$',
            priceInfo: 'per month, billed annually',
            buttonText: 'Get Started',
            tariffOptions: 'Access to:',
            optionList: ['100 million Compute Units per month', '1500 CU/s API throughput', '5 RPC Nodes', 'Pay in crypto']
        },
        {
            name: 'Extended Access',
            subName: 'Contact us to find a custom solution for your business',
            price: '49$',
            priceInfo: 'per month, billed annually',
            buttonText: 'Contact Us',
            tariffOptions: 'Everything in Business, plus:',
            optionList: ['Custom throughput limits', '24/7 support and monitoring', 'Early access to new features', 'Committed use discounts', 'Faster metadata refreshes']
        },
    ]

    return (
        <div className={styles.tariffs}>
            {tariffs && (
                tariffs.map((item, index) => <Tariff key={index} tariff={item} />)
            )}
        </div>
    )
}

export default Tariffs;