import React from "react";
import styles from './HeaderInfo.module.scss';

const HeaderInfo = ({tariffPeriod ,setTariffPeriod}) => {
    return (
        <div className={styles.headerInfo}>
            <h2>Plans built for everyone</h2>
            <p className={styles.subTitle}>Get the power and customization you need to build world-class blockchain projects</p>
            <div className={styles.headerInfo__chooser}>
                <div className={styles.headerInfo__chooserItem}>
                    <input type="radio" name='chooseTariff' checked={tariffPeriod === 'Monthly'}
                           onChange={() => setTariffPeriod('Monthly')} />
                    <p className={tariffPeriod === 'Monthly' ? styles.chooseActive : null}><span>Monthly</span></p>
                </div>
                <div className={styles.headerInfo__chooserItem}>
                    <input type="radio" name='chooseTariff' checked={tariffPeriod === 'Annually'}
                           onChange={() => setTariffPeriod('Annually')} />
                    <p className={tariffPeriod === 'Annually' ? styles.chooseActive : null}><span>Annually</span></p>
                </div>
            </div>
        </div>
    )
}

export default HeaderInfo;