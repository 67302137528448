import React from "react";
import styles from './Blogs.module.scss';
import BlogItem from "./blogItem";
import {useAppSelector} from "../../../hooks/redux";
import {getBlogs} from "../../../store/selectors";

const Blogs = ({change = false}) => {
    const blogsList = useAppSelector(getBlogs);

    return (
        <div className={styles.blogs}>
            <ul className={styles.blogs__list}>
                {blogsList && (
                    blogsList.map((item, index) =>
                        <BlogItem key={index} title={item.title} date={item.date} id={item.id} change={change} />
                    )
                )}
            </ul>
        </div>
    )
}

export default Blogs;