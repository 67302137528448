import React from "react";
import styles from './ExploreApis.module.scss';
import image1 from '../../../assets/images/boxImage1.png';
import image2 from '../../../assets/images/boxImage2.png';
import image3 from '../../../assets/images/boxImage3.png';
import image4 from '../../../assets/images/boxImage4.png';
import image5 from '../../../assets/images/boxImage5.png';

const ExploreApis = () => {
    const boxes = [
        {image: image1, title: 'Web3 Data API', text: 'Get all the blockchain data you need with our highly scalable APIs.'},
        {image: image2, title: 'Token API', text: 'Access all the information you need for any ERC20 tokens.'},
        {image: image3, title: 'Wallet API', text: 'Get all the balances and transactions for a specific wallet address.'},
        {image: image4, title: 'Blockchain API', text: 'Get the Blocks, Transactions and logs data of a blockchain.'},
        {image: image5, title: 'Authentication API', text: ' Authenticate users with their wallet to safely interact with your backend.'},
    ]

    return (
        <div className={styles.exploreApis}>
            <h2>Explore APIs usage </h2>
            <div className={styles.wrapper}>
                {boxes && boxes.length > 1 && (
                    boxes.map((item, index) => (
                        <div className={styles.box} key={index}>
                            <div className={styles.box__image}>
                                <img src={item.image} alt=""/>
                            </div>
                            <p className={styles.box__title}>{item.title}</p>
                            <p className={styles.box__description}>{item.text}</p>
                        </div>
                    ))
                )}

            </div>
        </div>
    )
}

export default ExploreApis;