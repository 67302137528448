import React from "react";
import styles from './BlogView.module.scss';

const BlogItem2 = () => {
    return (
        <>
            <div>
                <p className={styles.BlogView__description}>
                    Do you want your own project using blockchain technology? Well, first you need to have your own
                    node. Or use Y-API.
                </p>
                <div className={styles.BlogView__block}>
                    <h4>Briefly about nodrunning</h4>
                    <p>The basis of any decentralized network is a node — a collection of hardware devices and software
                        that stores a complete copy of the decentralized network and processes data. A large number of
                        nodes working in parallel and interacting with each other form a decentralized environment with
                        a distributed database. Each blockchain has its own requirements for nodes and features of their
                        operation, but the principle of basic nodes is practically the same for all of them. Nodas
                        provide their owners with many advantages, but at the same time they cause a lot of trouble and
                        expenses, so in order to profitably keep a noda, you should have certain knowledge and be ready
                        for different situations.
                    </p>
                </div>
                <div className={styles.BlogView__block}>
                    <h4>What is needed for nodrunning?</h4>
                    <p>To maintain your own node of a decentralized network, you need both
                        practical knowledge and
                        considerable funds, without which nothing will work. In particular, this is required for the
                        purchase or lease of a hardware part where all the necessary calculations take place. For
                        example, at the time of writing this article, the criteria for an Ethereum node looks like
                        this:</p>
                    <span className={styles.beforeList}>Minimum characteristics:</span>
                    <ul>
                        <li>4-8 GB of RAM;</li>
                        <li>2 TB SSD.</li>
                    </ul>
                    <span className={styles.beforeList}>Recommended:</span>
                    <ul>
                        <li>Intel NUC 7 gen and above, Processor x86;</li>
                        <li>Ping — 500 ms;</li>
                        <li>Interface component (keyboard, mouse).</li>
                    </ul>
                    <p>And for the TRON blockchain like this:</p>
                    <span className={styles.beforeList}>Minimum characteristics:</span>
                    <ul>
                        <li>32 GB RAM;</li>
                        <li>2.5 TB SSD.</li>
                    </ul>
                    <span className={styles.beforeList}>Recommended:</span>
                    <ul>
                        <li>CPU 16 cores;</li>
                        <li>Bandwith: 100M.</li>
                    </ul>
                    <p>It is worth noting that to synchronize a full Ethereum node, at least 1 TB of free disk space is
                        required, and this amount steadily increases as the number of records in the decentralized
                        database grows. Also, SSDs should be used for nodes, which provide a higher speed of saving
                        information than HDDs, which is extremely important at the block verification stage.
                    </p>
                </div>
                <div className={styles.BlogView__block}>
                    <h4>What are the benefits of own node?</h4>
                    <ul>
                        <li>Direct access to the network. You can also connect all the necessary crypto wallets at once
                            and avoid failures and overloads that occur in public nodes;
                        </li>
                        <li>Increased privacy. First of all, it concerns the storage of IP addresses and data about
                            endpoints;
                        </li>
                        <li>Secure storage of private keys;</li>
                        <li>Sandbox. This, first of all, concerns developers, who get the opportunity to test any
                            ideas.
                        </li>
                    </ul>
                </div>
                <div className={styles.BlogView__block}>
                    <h4>Difficulties and potential dangers</h4>
                    <p className={styles.beforeList}>Previously, we wrote purely about hardware, but the main reason for
                        failures in nodraning is precisely the human factor. Technical support of the node requires 24/7
                        attention and high qualification of the administrator. As with other Linux systems, there are
                        three constant dangers to contend with:</p>
                    <ul>
                        <li>DDoS</li>
                        <li>brute force</li>
                        <li>Social engineering.</li>
                    </ul>
                    <p>All together create additional workload and risks for the user or a disproportionate increase in
                        the overall project budget. Therefore, we offer a reasonable alternative.</p>
                </div>
                <div className={styles.BlogView__block}>
                    <h4>Benefits from Y-API</h4>
                    <p className={styles.beforeList}>The main thing to understand is that we have already deployed nodes
                        and are ready to share access to them with clients of our service. The Y-API team assumes all
                        responsibility for the operation of nodes and stable access to them; all you have to do is sign
                        up for the appropriate plan and choose the APIs you need for your project. So:</p>
                    <ul>
                        <li>Y-API subscribers do not need to engage in nodraning and take into account all the
                            requirements for this complex process mentioned above;
                        </li>
                        <li>You choose only the APIs you need and use them according to the terms of your subscription.
                            So if you only need a few features, you choose a lower cost package and don't pay for what
                            you don't need;
                        </li>
                        <li>If you need specific custom capabilities, all this can be implemented on the basis of Y-pi
                            individually;
                        </li>
                        <li>Y-pi support service will always help you in case of any difficulties.</li>
                    </ul>
                </div>
                <div className={styles.BlogView__block}>
                    <p>If you have your own business idea related to blockchain technology, don't let no-running
                        problems ruin your plans! Let's combine your ideas with our knowledge and development for common
                        success!</p>
                </div>
            </div>
        </>
    )
}

export default BlogItem2;