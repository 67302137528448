import React from "react";
import styles from './Tariffs.module.scss';

const Tariff = ({tariff}) => {
    return (
        <div className={styles.tariff}>
            <h4>{tariff.name}</h4>
            <p className={styles.tariff__subName}>{tariff.subName}</p>
            <div className={styles.tariff__priceBox}>
                <p className={styles.tariff__price}>{tariff.price}</p>
                {tariff && tariff.priceInfo && (
                    <span className={styles.tariff__priceInfo}>{tariff.priceInfo}</span>
                )}
            </div>
            <button className={styles.tariff__action}><span>{tariff.buttonText}</span></button>
            <p className={styles.tariff__options}>{tariff.tariffOptions}</p>
            <ul>
                {tariff && (
                    tariff.optionList.map((item, index) => <li key={index}>{item}</li>)
                )}
            </ul>
            <div className={styles.tariff__learnMore}>
                <a href="#">Learn More</a>
            </div>
        </div>
    )
}

export default Tariff;