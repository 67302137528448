import React from "react";
import styles from './BlogView.module.scss';
import {Link} from "react-router-dom";

const BlogItem1 = () => {
    return (
        <>
            <div>
                <p className={styles.BlogView__description}>
                    If you're reading this right now, we're 99% sure you need a modern crypto api. Well, you've come to the right place!
                </p>
                <div className={styles.BlogView__block}>
                    <h4>What is Y-API?</h4>
                    <p>Y-API is a solution created for anyone who has ideas for a business using the capabilities of WEB 3.0, but does not have the ability or time to develop integrations with blockchains themselves.</p>
                    <p>Y-API offers secure and reliable crypto wallets for all transactions with electronic assets. Thanks to our API, you will get all the features you need to create and customize your own crypto wallets for different decentralized networks.</p>
                </div>
                <div className={styles.BlogView__block}>
                    <h4>What networks are supported?</h4>
                    <p className={styles.beforeList}>Already today, right now, we offer the five most popular decentralized networks. Including:</p>
                    <ul>
                        <li>Bitcoin;</li>
                        <li>Ethereum;</li>
                        <li>Litecoin;</li>
                        <li>Tron and Binance smart Chain.</li>
                    </ul>
                    <p>The next Y-API update, already planned for 2025, is support for the TON decentralized network and deeper integration with their WEB 3.0 infrastructure. The team of authors sees great potential in TON and is preparing a number of additional services, including the development of a Web App based on this blockchain.</p>
                    <p>Another expected future update will be API development for the Solana decentralized network. However, the obvious advantage of Y-API is the ability to custom develop the methods you need for a specific network. Such a project will be much easier to implement with the specialists of our company, since ⅓ of the code you need is already written.</p>
                </div>
                <div className={styles.BlogView__block}>
                    <h4>How does Y-API help?</h4>
                    <p>With Yapi, you don't need to run your own NOD of the desired network and spend time, and most importantly resources, on its maintenance. You can learn more about nodraning in the article <Link to={'/blogs/2'}>"How to skip nodrunning with Y-API and save your money and nerves?"</Link> of our blog. Y-API provides access and APIs on a subscription basis and provides you with everything you need for a stable and productive operation.</p>
                    <p>On our website you will find a complete <a href="https://api.y-api.com/api/v1/docs/" title="documentation">documentation</a> of the methods offered by Yapi. We provide examples of requests and received responses so that it is easy and convenient for you to integrate our service into your project</p>
                    <p>But the main thing: even if you don't program, but want to enter the world of WEB3, the Yapi team will help you implement your own project and create unique crypto APIs specifically for your business. We have been working for many years and have extensive expertise as a developer for various decentralized networks.</p>
                </div>
                <div className={styles.BlogView__block}>
                    <h4>How to try our service?</h4>
                    <p>For full use, you need to subscribe to the required APIs. The best way would be to contact Y-API managers for a detailed presentation of the product and clarification of your needs. However, even before that, you can try any APIs for free to see if they work.</p>
                    <p>Don't waste time looking for solutions to complex issues! Use Y-API's API, try our solution in real time or let's discuss a custom solution. We work to save you time and money with efficient, ready-made methods for interacting with decentralized networks!</p>
                    <p>We are also ready to attract external investments and partnerships. Anyway — please, contact us!</p>
                </div>
            </div>
        </>
    )
}

export default BlogItem1;