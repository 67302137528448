import { combineReducers, configureStore } from '@reduxjs/toolkit';

import mainSlice from './main-slice';


const rootReducers = combineReducers({
    main: mainSlice,
});

export const store = configureStore({
    reducer: rootReducers
});
