import React from "react";
import styles from './WhatAPI.module.scss';

const Tabs = ({tabs, setShowContent, showContent}) => {

    return (
        <>
            {tabs && (
                tabs.map((item, index) => (
                    <div key={index} className={styles.tab}>
                        <input checked={showContent.id === item.id} type="radio" name='whatApi' onChange={() => setShowContent(item)}/>
                        <p>
                            <img src={item.icon} alt=""/>
                            <span>{item.tabText}</span>
                        </p>
                        <div className={styles.secondContent}>
                            <span>{item.text}{item.subText}</span>
                        </div>
                    </div>
                )))
            }
        </>
    )
}

export default Tabs;